<template>
  <div v-capture-scroll="onScroll" class="d-flex gap-4 align-items-top w-100">
    <div
      class="position-relative pb-2"
      :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
      :style="{ height: getHeight(`status-${billing.id}`) }"
    >
      <span
        class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center border border-4"
        :class="{
          [`bg-${RECONCILIATION_STATUS_COLOR[billing.status]}`]: true,
          'border-grey': loading,
          'border-white': !loading,
        }"
      />
    </div>
    <div
      :id="`card-${billing.id ? `billing-${billing.id}` : `${unbilledOrdersCardId}-${index}`}`"
      class="card w-100 billing-row"
      :class="{
        'border-dashed': billing.missingEventId,
        'uncertain-billing': billing.type === BILLING_TYPE.UNCERTAIN_BILLING,
      }"
    >
      <div
        class="header"
        data-bs-toggle="collapse"
        :data-bs-target="`#${billing.id ? `billing-${billing.id}` : `${unbilledOrdersCardId}-${index}`}`"
        aria-expanded="false"
        :aria-controls="billing.id ? `billing-${billing.id}` : `${unbilledOrdersCardId}-${index}`"
      >
        <div
          class="d-flex align-items-stretch flex-grow-1 h-100"
          :class="$direction === 'rtl' ? 'pe-4 ps-3' : 'ps-4 pe-3'"
        >
          <div class="align-self-center" :class="$direction === 'rtl' ? 'ps-2' : 'pe-2'" style="width: 250px">
            <TruncatedText class="flex-fill">
              <ReturnIcon
                v-if="billing.totalAmount < 0"
                class="credit-note-icon"
                :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
                style="padding-bottom: 2px"
                width="14"
              />
              <h4 v-if="billing.type === BILLING_TYPE.UNBILLED_ORDER">
                <p class="fw-bold">
                  {{
                    $tc(
                      'reconciliationModal.billingStatus.unbilledOrders',
                      billing.orderLinks.length + billing.orderDifferences.length
                    )
                  }}
                </p>
              </h4>
              <h4 v-else-if="billing.missingEventId" class="disabled fw-normal">
                {{ $t('reconciliationModal.billingStatus.missingDoc') }} (
                {{ getMissingType(billing.source.document) }})
              </h4>
              <Button
                v-else
                data-bs-toggle="collapse"
                type="link"
                class="p-0 text-gray"
                @click.stop="() => $emit('handleOpenDocument', billing.source.document.id)"
              >
                <h4 class="link fw-bold">
                  {{ $t(`document.exports.schema.type.shortName.${billing.source.document.type}`) }}
                  {{ billing.source.document.documentNumber }}
                </h4>
              </Button>
            </TruncatedText>
          </div>
          <div v-if="billing.type === BILLING_TYPE.UNBILLED_ORDER" class="align-self-center" style="width: 140px">
            <p v-if="billing.maxDate === billing.minDate">{{ formatDate(billing.maxDate) }}</p>
            <p v-else>{{ formatDate(billing.maxDate) }} - {{ formatDate(billing.minDate) }}</p>
          </div>
          <div v-else class="align-self-center" :class="{ disabled: !billing.id }" style="width: 140px">
            {{ formatDate(billing.date) }}
          </div>
          <div class="align-self-center" style="width: 140px">
            <el-tooltip v-if="billing.totalAmount" placement="top">
              <div slot="content">
                <div>{{ $t('reconciliationModal.net') }}: {{ formatMoneyAbs(billing.netAmount) }}</div>
                <div>{{ $t('commons.vat') }}: {{ formatMoneyAbs(billing.taxAmount) }}</div>
                <div>{{ $t('commons.total') }}: {{ formatMoneyAbs(billing.totalAmount) }}</div>
              </div>
              <span class="total-amount">
                {{ formatMoneyAbs(billing.totalAmount) }}
              </span>
            </el-tooltip>
            <div v-else :class="{ disabled: !billing.id }">-</div>
          </div>
          <div class="flex-fill align-self-center">
            <div class="d-flex gap-1">
              <Tag
                v-if="!loading && billing.status"
                :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
                :type="
                  [BILLING_TYPE.UNBILLED_ORDER, BILLING_TYPE.UNCERTAIN_BILLING].includes(billing.type)
                    ? 'neutral'
                    : RECONCILIATION_TAG_COLOR[billing.status]
                "
              >
                {{
                  billing.type === BILLING_TYPE.UNCERTAIN_BILLING
                    ? $t(`billing.exports.status.pending`)
                    : $t(`billing.exports.status.${billing.status}`)
                }}
              </Tag>
              <p v-if="billing.status === RECONCILIATION_STATUSES.LOADING" class="invisible">Place Holder</p>
              <p v-else>{{ getBillingDetails(billing) }}</p>
              <div v-if="billing.missingEventId">
                ({{ $t('commons.reference') }}:
                <Button
                  data-bs-toggle="collapse"
                  type="link"
                  class="p-0 text-typography-primary"
                  @click.stop="() => $emit('handleOpenDocument', billing.parentDocumentId)"
                >
                  <p class="link">
                    {{ getParentDocumentText(billing.parentDocument) }}
                  </p> </Button
                >)
              </div>
              <div
                v-if="
                  billing.type !== BILLING_TYPE.UNCERTAIN_BILLING && isBillingHasMatchedCredits(billing) && !loading
                "
                class="d-flex align-items-center"
              >
                <el-tooltip placement="top" effect="dark">
                  <div slot="content">
                    <div v-for="(line, i) in getMatchedCreditsTooltip(billing)" :key="i">
                      {{ line }}
                    </div>
                  </div>
                  <span class="d-flex">
                    <LinkIconNew width="16" height="16" />
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div v-if="billing.type !== BILLING_TYPE.UNCERTAIN_BILLING" class="align-self-center" style="width: 165px">
            <div v-if="billing.totalAmount" class="gap-1 d-flex align-items-center">
              <div
                v-if="billing.paymentStatus.paymentState === BILLING_PAYMENT_STATE.NOT_PAYED"
                class="d-flex gap-1 align-items-center text-typography-secondary"
              >
                <ClockIcon width="16" height="16" />
                {{
                  $t(
                    `reconciliationModal.${
                      billing.totalAmount >= 0 ? 'billingStatus.notPayedYet' : 'creditOrdersTable.notRealizedYet'
                    }`
                  )
                }}
              </div>
              <div
                v-else-if="billing.paymentStatus.paymentState === BILLING_PAYMENT_STATE.PAYMENT_IN_PROGRESS"
                data-bs-toggle="collapse"
                @click.stop="() => $emit('handlePaymentLinkClicked', billing.paymentStatus.paymentId)"
              >
                <el-tooltip v-if="billing.totalAmount" placement="top">
                  <div slot="content">
                    <div>
                      {{
                        $t('reconciliationModal.billingStatus.partiallyPaidToolTip', {
                          paidAmount: formatMoney(billing.paymentStatus.paidAmount),
                          totalAmount: formatMoney(billing.totalAmount),
                        })
                      }}
                    </div>
                  </div>

                  <div class="d-flex gap-1 align-items-center hover">
                    <RefreshIcon width="16" height="16" />
                    <span class="hover-underline">{{ $t('reconciliationModal.billingStatus.paymentInProgress') }}</span>
                  </div>
                </el-tooltip>
              </div>
              <div
                v-else-if="billing.paymentStatus.paymentState === BILLING_PAYMENT_STATE.PARTIALLY_PAYED"
                data-bs-toggle="collapse"
                @click.stop="() => $emit('handlePaymentLinkClicked', billing.paymentStatus.paymentId)"
              >
                <el-tooltip v-if="billing.totalAmount" placement="top">
                  <div slot="content">
                    <div>
                      {{
                        $t('reconciliationModal.billingStatus.partiallyPaidToolTip', {
                          paidAmount: formatMoney(billing.paymentStatus.paidAmount),
                          totalAmount: formatMoney(billing.totalAmount),
                        })
                      }}
                    </div>
                  </div>

                  <div class="d-flex gap-1 align-items-center hover">
                    <PaymentPartial width="16" height="16" />
                    <span class="hover-underline">{{ $t('reconciliationModal.billingStatus.partiallyPaid') }}</span>
                  </div>
                </el-tooltip>
              </div>
              <div
                v-else
                data-bs-toggle="collapse"
                class="d-flex gap-1 align-items-center hover"
                @click.stop="() => $emit('handlePaymentLinkClicked', billing.paymentStatus.paymentId)"
              >
                <ApprovedIcon width="16" height="16" />
                <span class="hover-underline">
                  {{
                    $t(
                      `reconciliationModal.${
                        billing.totalAmount >= 0 ? 'billingStatus.fullyPayed' : 'creditOrdersTable.fullyRealized'
                      }`
                    )
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end align-self-center" style="width: 32px">
            <el-dropdown
              v-if="
                (billing.type !== BILLING_TYPE.UNCERTAIN_BILLING && billing.id) || (isAdmin && billing.missingEventId)
              "
              ref="dropDownRef"
              class="pe-2 active"
              trigger="click"
              :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
              @command="(action) => handleInnerAction(action, billing)"
            >
              <Button type="icon" data-bs-toggle="collapse" class="p-0 more-btn" @click.stop>
                <KebabIcon width="20" height="20" max-width="20" max-height="20" />
              </Button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="billing.id" :command="INNER_TABLE_ACTIONS.OPEN_BILLING_MODAL">
                  {{ $t('reconciliationModal.showBilling') }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="isAdmin && billing.missingEventId && hasReconciliationManage"
                  :disabled="!isAdmin"
                  :command="INNER_TABLE_ACTIONS.RESOLVE_MISSING_EVENT"
                >
                  {{ $t('billing.billingManagement.expandableActivity.actions.resolveMissingEvent') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div
        v-if="billing.type === BILLING_TYPE.UNBILLED_ORDER"
        :id="billing.id ? `billing-${billing.id}` : `${unbilledOrdersCardId}-${index}`"
        class="collapse"
      >
        <BillingTable
          :billing="billing"
          :delivery-task-ids="deliveryTasksIds"
          :supplier="supplier"
          :business="business"
          :order-differences="billing.orderDifferences"
          @refetch="() => $emit('handleUnbilledOrdersTableRefetch')"
        />
      </div>
      <div
        v-else-if="billing.type !== BILLING_TYPE.UNCERTAIN_BILLING && billing.totalAmount >= 0"
        :id="billing.id ? `billing-${billing.id}` : `${unbilledOrdersCardId}-${index}`"
        class="collapse"
      >
        <BillingTable
          :billing="billing"
          :delivery-task-ids="deliveryTasksIds"
          :business="business"
          :missing-event="billing.missingDeliveryNotes"
          @refetch="() => $emit('handleBillingTableRefetch')"
        />
      </div>
      <div
        v-else-if="billing.type !== BILLING_TYPE.UNCERTAIN_BILLING && billing.totalAmount < 0"
        :id="billing.id ? `billing-${billing.id}` : `${unbilledOrdersCardId}-${index}`"
        class="collapse"
      >
        <CreditOrdersTable
          :billing="billing"
          :credit-order-obj-arr="getCreditOrderObjArrForBilling(billing)"
          :delivery-task-ids="deliveryTasksIds"
          :missing-event="billing.missingDeliveryNotes"
          :month="reconciliationMonth"
          @refetch="() => $emit('handleBillingTableRefetch')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { flatten } from 'ramda';

import { Button, Tag, TruncatedText } from '@/modules/core';
import {
  ReturnIcon,
  ClockIcon,
  PaymentPartial,
  RefreshIcon,
  LinkIconNew,
  ApprovedIcon,
  KebabIcon,
} from '@/assets/icons';
import { useUser } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useGlobalPermissions } from '@/modules/permissions';

import { captureScroll } from '@/directives/capture-scroll';

import { getHeight } from '../utils';
import { BillingTable, CreditOrdersTable } from '.';
import {
  RECONCILIATION_STATUS_COLOR,
  RECONCILIATION_STATUSES,
  RECONCILIATION_TAG_COLOR,
  BILLING_TYPE,
  BILLING_PAYMENT_STATE,
  INNER_TABLE_ACTIONS,
} from '../../';

export default {
  components: {
    Button,
    Tag,
    TruncatedText,
    ReturnIcon,
    ClockIcon,
    BillingTable,
    CreditOrdersTable,
    PaymentPartial,
    RefreshIcon,
    LinkIconNew,
    ApprovedIcon,
    KebabIcon,
  },
  directives: {
    captureScroll,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    billing: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    deliveryTasksIds: {
      type: Array,
      required: true,
    },
    billings: {
      type: Array,
      required: true,
    },
    reconciliationMonth: {
      type: String,
      required: true,
    },
    supplier: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { isAdmin } = useUser();
    const { formatCentsToCurrency } = useCurrency();
    const { hasReconciliationManage } = useGlobalPermissions();
    const formatMoneyAbs = (value) => {
      return formatCentsToCurrency(Math.abs(Number(value))) ?? '-';
    };

    const formatMoney = (value) => {
      return formatCentsToCurrency(value) ?? '-';
    };

    return {
      RECONCILIATION_STATUS_COLOR,
      RECONCILIATION_STATUSES,
      RECONCILIATION_TAG_COLOR,
      BILLING_TYPE,
      BILLING_PAYMENT_STATE,
      INNER_TABLE_ACTIONS,
      getHeight,
      formatMoneyAbs,
      formatMoney,
      isAdmin,
      hasReconciliationManage,
    };
  },

  computed: {
    unbilledOrdersCardId() {
      return this.getCardId('billing');
    },
  },
  methods: {
    onScroll() {
      if (this.$refs.dropDownRef) {
        this.$refs.dropDownRef.visible = false;
      }
    },
    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
            timeZone: 'UTC',
          })
        : '-';
    },
    getMissingType(document) {
      const documentTypesText = document.type
        .map((type) => this.$t(`document.exports.schema.type.shortName.${type}`))
        .join(' / ');
      const documentNumber = document.documentNumber ?? '';
      return `${documentTypesText} ${documentNumber}`;
    },
    getBillingDetails(billing) {
      switch (billing.status) {
        case RECONCILIATION_STATUSES.APPROVED:
          return this.isBillingHasMatchedCredits(billing)
            ? this.$i18n.t('reconciliationModal.billingStatus.matchedToOrderWithCredit')
            : this.$i18n.t('reconciliationModal.billingStatus.matchedToOrder');
        case RECONCILIATION_STATUSES.NOT_APPROVED: {
          const notMatched = this.isBillingHasMatchedCredits(billing)
            ? 'notMatchedToOrderWithCredit'
            : 'notMatchedToOrder';
          return this.$i18n.t(`reconciliationModal.billingStatus.${notMatched}`, {
            amount: this.formatMoney(billing.imbalanceAmount),
          });
        }
        case RECONCILIATION_STATUSES.PENDING: {
          if (billing.type === BILLING_TYPE.UNBILLED_ORDER) {
            return this.$i18n.t('reconciliationModal.billingStatus.waitingForCharge');
          }
          if (billing.type === BILLING_TYPE.UNCERTAIN_BILLING) {
            return this.$i18n.t('reconciliationModal.billingStatus.uncertainEvent');
          }
          if (!billing.id) {
            return this.$i18n.t('reconciliationModal.billingStatus.missingDoc');
          }
          if (billing.isMissingDocumentation || billing.missingDeliveryNotes.length) {
            return this.$i18n.t('reconciliationModal.billingStatus.missingInfo');
          }
          if (
            billing.allocationRequired &&
            !billing.eventReferences.find(({ allocationNumber }) => allocationNumber)?.allocationNumber
          ) {
            return this.$i18n.t('reconciliationModal.billingStatus.allocationNumberMissing');
          }
          return;
        }
        default:
          return;
      }
    },
    isBillingHasMatchedCredits(billing) {
      const relatedCredits = this.getRelatedCredits(billing);
      return relatedCredits.length > 0;
    },
    getRelatedCredits(billing) {
      return billing.related
        .filter(({ totalAmount }) => totalAmount < 0)
        .map((related) => ({
          ...related,
          links: related.orderLinks.filter((orderLink) =>
            billing.orderLinks.some((link) => orderLink.order && link.order && orderLink.order.id === link.order.id)
          ),
        }))
        .filter(({ links }) => links.length);
    },
    getMatchedCreditsTooltip(billing) {
      const relatedCredits = this.getRelatedCredits(billing);
      return flatten(
        relatedCredits.map(({ source, links }) =>
          links.map(({ amount }) =>
            this.$t('reconciliationModal.tooltips.matchedCredits', {
              amount: this.formatMoneyAbs(Math.abs(amount)),
              documentNumber: source.document.documentNumber,
            })
          )
        )
      );
    },
    getCreditOrderObjArrForBilling(billing) {
      const orders = billing.orderLinks.map(({ order }) => order);
      const possibleMatchedBillings = this.billings.filter((b) => b.totalAmount >= 0);
      return orders.map((currOrder) => ({
        order: currOrder,
        billing: possibleMatchedBillings.find((billing) =>
          billing.orderLinks.some(({ order }) => order.id === currOrder.id)
        ),
      }));
    },
    getParentDocumentText(parentDocument) {
      if (!parentDocument) return;
      const documentDateText = new Date(parentDocument.issueDate).toLocaleDateString(this.$i18n.locale, {
        month: 'long',
        year: '2-digit',
      });
      const documentTypeText = this.$t(`document.exports.schema.type.shortName.${parentDocument.type}`);
      return `${documentTypeText} ${documentDateText}`;
    },
    handleInnerAction(action, billing) {
      switch (action) {
        case INNER_TABLE_ACTIONS.OPEN_BILLING_MODAL:
          this.$emit('handleOpenEventMap', { id: billing.id, type: 'billing' });
          break;
        case INNER_TABLE_ACTIONS.RESOLVE_MISSING_EVENT:
          this.$emit('patchMissingEvent', { id: billing.missingEventId, data: { resolved: true } });
          break;
        default:
          break;
      }
    },
    getCardId(cardName) {
      return `${cardName}-${Math.floor(Math.random() * 1000000)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
$darken-gray: #e3e4e6;

.text-gray {
  color: #46494f;
}

.billing-row {
  .more-btn {
    width: 32px;
    height: 32px;
    visibility: hidden;
    &:hover {
      background: #e2e4e8;
      border-radius: 0.25rem !important;
    }
  }
  &:hover .more-btn {
    visibility: visible;
  }
  .card-extended-header .more-btn {
    &:hover {
      background: #cfd2d9 !important;
    }
  }
}

.link {
  text-decoration: underline;
  &:hover {
    color: $primary;
  }
}

.hover {
  &:hover {
    color: $primary;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.border-dashed {
  border: 1px dashed $darken-gray;
}

.uncertain-billing {
  background-image: url('~@/assets/images/diagonal-striped-pattern.png');
}

.total-amount {
  border-bottom: 1px dashed #94989f;
}

.card .header:hover .total-amount {
  border-bottom: 1px dashed $typography-primary;
}

div[aria-expanded='true'] {
  border-bottom: 1px solid #e5e8ea;
}

.card .header {
  height: 52px;
  color: #46494f;
}

.card:not(.uncertain-billing) .header {
  cursor: pointer;

  &:hover:not(svg):not(.credit-circle) {
    background: $gray;
    color: $typography-primary;
    border-radius: 6px;
  }
}

.disabled {
  color: #94989f !important;
}

.credit-note-icon {
  [dir='ltr'] & {
    transform: scaleX(-1);
  }
}
</style>
