<template>
  <div>
    <div class="d-flex gap-2 p-4 border-bottom bg-light-gray" :class="{ description: !paymentDifferences.length }">
      <template v-if="paymentDifferences.length">
        <NoticeIcon width="16" height="16" class="icon-margin" />
        <p>
          {{
            $tc('reconciliationModal.paymentDifferencesTable.noCustomerPayment', paymentDifferences.length, {
              customer: business.name,
            })
          }}
        </p>
      </template>
      <template v-else>
        <CheckCircleIcon width="16" height="16" class="icon-margin" />
        <p>{{ $tc('reconciliationModal.paymentDifferencesTable.noDiffFound') }}</p>
      </template>
    </div>
    <Table
      v-if="paymentDifferences.length"
      :columns="columns"
      :data="tableData"
      rounded
      :hover="isAdmin"
      @row-click="handleRowClick(paymentDifferences[$event])"
    >
      <template #cell-receipt="{ rowData: { receipt } }">
        <Button v-if="receipt" type="link" class="p-0" @click.stop="$emit('open-document', receipt.id)">
          <p>{{ $t(`document.exports.schema.type.shortName.${receipt.type}`) }} {{ receipt.documentNumber }}</p>
        </Button>
        <p v-else>-</p>
      </template>
    </Table>
  </div>
</template>

<script>
import { options } from '@/locale/dateConfig';
import { Table, Button } from '@/modules/core';
import { NoticeIcon, CheckCircleIcon } from '@/assets/icons';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useUser } from '@/modules/auth';

const TABLE_HEADER = {
  RECEIPT_DATE: 'receiptDate',
  RECEIPT: 'receipt',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
};

export default {
  components: {
    Table,
    Button,
    NoticeIcon,
    CheckCircleIcon,
  },
  props: {
    paymentDifferences: { type: Array, default: () => [] },
    business: { type: Object, required: true },
  },
  setup() {
    const { isAdmin } = useUser();
    const { formatToCurrency } = useCurrency();

    return {
      isAdmin,
      formatToCurrency,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t(`reconciliationModal.paymentDifferencesTable.${TABLE_HEADER.RECEIPT_DATE}`),
          key: TABLE_HEADER.RECEIPT_DATE,
        },
        {
          header: this.$t(`reconciliationModal.paymentDifferencesTable.${TABLE_HEADER.RECEIPT}`),
          key: TABLE_HEADER.RECEIPT,
        },
        {
          header: this.$t(`reconciliationModal.paymentDifferencesTable.${TABLE_HEADER.AMOUNT}`),
          key: TABLE_HEADER.AMOUNT,
        },
        {
          header: this.$t(`reconciliationModal.paymentDifferencesTable.${TABLE_HEADER.PAYMENT_METHOD}`),
          key: TABLE_HEADER.PAYMENT_METHOD,
        },
      ];
    },
    tableData() {
      return this.paymentDifferences.map(
        ({ supplierPayment: { date, eventReferences, amount, isRefund, transactions } }) => {
          return {
            [TABLE_HEADER.RECEIPT_DATE]: this.formatDate(date),
            [TABLE_HEADER.RECEIPT]: eventReferences[0].document,
            [TABLE_HEADER.AMOUNT]: this.formatMoney(amount * (isRefund ? -1 : 1)),
            [TABLE_HEADER.PAYMENT_METHOD]: this.$t(
              `payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`
            ),
          };
        }
      );
    },
  },
  methods: {
    handleRowClick() {
      if (this.isAdmin) this.$emit('match-payment-diffs');
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  border-radius: 0px 0px 6px 6px;
  border-bottom: 0px !important;
}

.icon-margin {
  margin-top: 2px;
}
</style>
