<template>
  <div class="py-4 bg-expandable" :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'">
    <Table :columns="columns" :data="tableData" :hover="false" small custom-class="items-table" show-index>
      <template #cell-orderDate="{ rowData: { orderDate } }">
        <div>{{ formatDate(orderDate) }}</div>
      </template>
      <template #cell-differenceAmount="{ rowData: { differenceAmount } }">
        <div>{{ formatMoney(differenceAmount) }}</div>
      </template>
      <template #cell-details="{ rowData: { details, solved } }">
        <div class="text-truncate">
          <template v-if="solved">
            <div>
              {{ $t(`modals.issues.itemsTable.details.solvedDifference`) }}
            </div>
          </template>
          <div :class="{ 'text-decoration-line-through': solved }">
            {{ details }}
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { computed } from 'vue';
import { isNil, omit, flatten } from 'ramda';

import i18n from '@/imports/startup/client/i18n';
import { percent } from '@/locale/numberConfig';
import { Table } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useBusinessById } from '@/modules/eventMapModal/compositions';

import { options } from '@/locale/dateConfig';
import { currency } from '@/locale/numberConfig';

export const TABLE_HEADER = {
  SKU: 'sku',
  PRODUCT: 'product',
  TERMS: 'terms',
  DIFFERENCE_TYPE: 'differenceType',
  DIFFERENCE_AMOUNT: 'differenceAmount',
  DETAILS: 'details',
};

const formatPercent = (number) => {
  return typeof number === 'number' && !Number.isNaN(number)
    ? Number(number / 100).toLocaleString(i18n.locale, percent)
    : null;
};

const formatMoney = (value) => {
  return (
    (typeof value === 'number' && !Number.isNaN(value) ? value.toLocaleString(i18n.locale, currency) : null) ?? '-'
  );
};

const formatQuantity = (number) => {
  return typeof number === 'number' && !Number.isNaN(number) ? Number(number).toLocaleString(i18n.locale) : null;
};

const getOrderItemTerms = (itemData) => {
  const { price, quantity, discount } = itemData;
  const terms = {};
  const texts = [];
  if (!isNil(price)) {
    terms.price = formatMoney(price);
  } else {
    terms.price = i18n.t('commons.unknown');
  }
  if (!isNil(discount)) {
    terms.discount = formatPercent(discount);
  }
  if (!isNil(quantity)) {
    terms.quantity = formatQuantity(quantity);
  }

  if (terms.quantity) {
    texts.push(terms.quantity);
  }
  if (terms.discount) {
    texts.push(`(${terms.price} + ${terms.discount})`);
  } else {
    texts.push(terms.price);
  }

  // do not change me to an english x - it messing all the text direction
  return texts.join(' ✕ ');
};

const getPriceDetails = (itemData) => {
  const {
    difference: { price, discount },
    parentContext,
  } = itemData;
  let billed;
  let ordered;
  if (price && discount) {
    billed = `${formatMoney(price.supplierValue)} + ${formatPercent(discount.supplierValue)}`;
    ordered = `${formatMoney(price.customerValue)} + ${formatPercent(discount.customerValue)}`;
  } else {
    if (price) {
      billed = formatMoney(price.supplierValue);
      ordered = formatMoney(price.customerValue);
    }
    if (discount) {
      billed = formatPercent(discount.supplierValue);
      ordered = formatPercent(discount.customerValue);
    }
  }
  if (billed && ordered)
    return i18n.t(`modals.issues.itemsTable.details.${parentContext}.amount`, {
      billed,
      ordered,
    });
  return null;
};

const getQuantityDetails = (itemData) => {
  const {
    difference: { quantity },
    parentContext,
    supplier,
    customer,
  } = itemData;

  return i18n.t(`modals.issues.itemsTable.details.${parentContext}.quantity`, {
    billed: formatQuantity(quantity.supplierValue),
    ordered: formatQuantity(quantity.customerValue),
    supplier,
    customer,
  });
};

export const createItemsData = (item) => ({
  [TABLE_HEADER.SKU]: item.product?.sku ?? '-',
  [TABLE_HEADER.PRODUCT]: item.product?.name,
  [TABLE_HEADER.TERMS]: getOrderItemTerms(item),
  [TABLE_HEADER.DIFFERENCE_TYPE]: i18n.t('modals.issues.itemsTable.diffType', {
    type: i18n.t(`modals.issues.itemsTable.types.${item.difference.type}`),
  }),
  [TABLE_HEADER.DIFFERENCE_AMOUNT]: item.difference.amount,
  [TABLE_HEADER.DETAILS]: item.difference.type === 'pricing' ? getPriceDetails(item) : getQuantityDetails(item),
  supplier: item.supplier,
  customer: item.customer,
  quantity: item.quantity,
  difference: item.difference ?? null,
  parentContext: item.parentContext,
  solved:
    item.difference.type === 'pricing'
      ? item.difference?.netPrice?.solved ?? false
      : item.difference?.quantity?.solved ?? false,
});

export default {
  components: {
    Table,
  },
  props: {
    order: { type: Object, required: true },
  },
  setup(props) {
    const businessId = computed(() => props.order.businessId);
    const { business } = useBusinessById(businessId);

    const { formatToCurrency } = useCurrency();

    return {
      business,
      formatToCurrency,
    };
  },
  computed: {
    tableData() {
      const orderItems = flatten(
        this.order.differences
          ? this.order.differences.map((diff) => {
              const diffs = [];
              const pricingDiffExists = diff.price || diff.discount;
              if (pricingDiffExists)
                diffs.push(this.createOrderItem(this.order, diff, this.business?.name, pricingDiffExists));
              if (diff.quantity) diffs.push(this.createOrderItem(this.order, diff, this.business?.name));
              return diffs;
            })
          : []
      );
      return orderItems.map(createItemsData);
    },
    columns() {
      return [
        {
          header: this.$t('modals.issues.itemsTable.table.header.sku'),
          key: TABLE_HEADER.SKU,
        },

        {
          header: this.$t('modals.issues.itemsTable.table.header.product'),
          key: TABLE_HEADER.PRODUCT,
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.terms'),
          key: TABLE_HEADER.TERMS,
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.differenceType'),
          key: TABLE_HEADER.DIFFERENCE_TYPE,
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.totalDifference'),
          key: TABLE_HEADER.DIFFERENCE_AMOUNT,
        },
        {
          header: this.$t('modals.issues.itemsTable.table.header.details'),
          key: TABLE_HEADER.DETAILS,
        },
      ];
    },
  },
  methods: {
    createOrderItem(order, diff, customer, isPricingDiff) {
      const item = order.products?.find((product) => product.productId === diff.productId);
      const orderItem = {
        orderDate: order.date,
        documentNumber: '',
        product: diff.product,
        difference: this.getDiffs(diff, isPricingDiff),
        parentContext: 'billed',
        customer,
      };

      if (item)
        return {
          ...item,
          ...orderItem,
        };
      else
        return {
          quantity: 0,
          discount: diff.discount?.supplierValue,
          price: diff.price?.supplierValue,
          ...orderItem,
        };
    },
    getDiffs(diff, pricing) {
      return {
        ...omit(pricing ? ['quantity'] : ['price', 'discount'], diff),
        amount: pricing ? diff.pricingAmount : diff.quantityAmount,
        type: pricing ? 'pricing' : 'quantity',
      };
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(i18n.locale, options.short) : i18n.t('commons.unknownDate');
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-expandable {
  background: #f6f7f9;
}

::v-deep .items-table {
  th,
  td {
    background: #f6f7f9;
  }

  tr {
    td {
      cursor: initial !important;
      background: #f6f7f9 !important;
    }
  }
}
</style>
